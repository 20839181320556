import { origins } from './common/origins';
import { DEFAULT_VIEWS } from './common/default-views';
import { filters } from './common/filters.conf';

export const environment = {
  origins,
  filters,
  defaultViews: DEFAULT_VIEWS,
  production: true,
  envName: 'pre',
  signatureHost: 'https://k3f11dgpc9.execute-api.eu-west-1.amazonaws.com',
  apiDomain: 'k3f11dgpc9.execute-api.eu-west-1.amazonaws.com',
  host: 'https://k3f11dgpc9.execute-api.eu-west-1.amazonaws.com/api',
  grid: { defaultLimit: 50, requestTimeout: 1000 },
  state: { interval: 10000 },
  tasks: { interval: 10000, taskInterval: 10000 },
  async: {
    defaultInterval: 10000,
    query: {
      interval: 4000,
      completeStatus: 'succeeded',
      errorStatus: 'failed',
    },
  },
  auth: {
    domain: 'bdret-pre.auth.eu-west-1.amazoncognito.com',
    cognitoDomain: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_eAYHRrV8F',
    auth0clientId: '3bbpcegi6o0talqpnpaie8ts2t',
    identityPoolId: 'eu-west-1:b4c80367-4b50-4bbe-8659-bb71b6e6c76f',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_eAYHRrV8F',
    clientId: '3bbpcegi6o0talqpnpaie8ts2t',
    scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
  },
};