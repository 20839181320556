let allOrigins = [
  {
    title: 'Tramos AT',
    origin: 'sabana_informe_instalaciones_tramos_at_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Tramos MT/BT',
    origin: 'sabana_informe_instalaciones_tramos_mtbt_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Agregado Tramos BT',
    origin: 'sabana_informe_instalaciones_tramos_bt_agrupados_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Posiciones',
    origin: 'sabana_informe_instalaciones_posiciones_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Máquinas',
    origin: 'sabana_informe_instalaciones_maquinas_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'CT',
    origin: 'sabana_informe_instalaciones_cts_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'EMF',
    origin: 'sabana_informe_instalaciones_emfs_vista',
    namespace: 'installations.instalaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Tramos AT',
    origin: 'sabana_informe_instalaciones_tramos_at_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Tramos MT/BT',
    origin: 'sabana_informe_instalaciones_tramos_mtbt_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Agregado Tramos BT',
    origin: 'sabana_informe_instalaciones_tramos_bt_agrupados_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Posiciones',
    origin: 'sabana_informe_instalaciones_posiciones_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Máquinas',
    origin: 'sabana_informe_instalaciones_maquinas_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'CT',
    origin: 'sabana_informe_instalaciones_cts_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'EMF',
    origin: 'sabana_informe_instalaciones_emfs_desagrupado_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Despachos con instalación',
    origin: 'sabana_informe_instalaciones_despachos_con_instalacion_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Despachos sin instalación',
    origin: 'sabana_informe_instalaciones_despachos_sin_instalacion_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Edificios y varios',
    origin: 'sabana_informe_instalaciones_edificios_y_varios_vista',
    namespace: 'installations.desagrupado',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },

  {
    title: 'Expedientes MT/BT',
    origin: 'sabana_informe_inspeccion_expedientes_mtbt_vista',
    namespace: 'installations.inspeccion',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Expedientes Despachos',
    origin: 'sabana_informe_inspeccion_expedientes_despachos_vista',
    namespace: 'installations.inspeccion',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'ELAs AT',
    origin: 'sabana_informe_inspeccion_elas_at_vista',
    namespace: 'installations.inspeccion',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'ELAs Despachos',
    origin: 'sabana_informe_inspeccion_elas_despachos_vista',
    namespace: 'installations.inspeccion',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },

  {
    title: 'Expedientes MT/BT',
    origin: 'sabana_informe_comunicaciones_expedientes_mtbt_vista',
    namespace: 'installations.comunicaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'ELAs AT',
    origin: 'sabana_informe_comunicaciones_elas_at_vista',
    namespace: 'installations.comunicaciones',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },

  {
    title: 'Cuadro General',
    origin: 'sabana_informe_instalaciones_cuadro_resumen_cuadro_general_vista',
    namespace: 'installations.cuadro_resumen',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Cuadro Cesiones',
    origin: 'sabana_informe_instalaciones_cuadro_resumen_cuadro_cesiones_vista',
    namespace: 'installations.cuadro_resumen',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Resumen por instalación',
    origin: 'sabana_informe_instalaciones_cuadro_resumen_resumen_por_instalaciones_vista',
    namespace: 'installations.cuadro_resumen',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Informe Instalaciones Despachos',
    origin: 'sabana_informe_instalaciones_despachos_detalle_vista',
    namespace: 'installations.despachos',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },
  {
    title: 'Expedientes Auditados',
    origin: 'sabana_informe_instalaciones_expedientes_auditados',
    namespace: 'installations.expedientes_auditados',
    reload: 'installations',
    reloadNicename: 'Informes Instalaciones Declaradas',
  },

  {
    title: 'STL Load Errors',
    origin: 'stl_load_errors',
    namespace: 'system',
  },
  {
    title: 'Seccionadores MT',
    origin: 'gdosecci_circular_vista',
    namespace: 'inventory',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Apoyos MT',
    origin: 'gdoapoyo_circular_vista',
    namespace: 'inventory',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'CTs (Envolventes)',
    origin: 'gdoct_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'CTs Sin Servicio',
    origin: 'gdoctss_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Apoyos AT',
    origin: 'gdoapoyo_t_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Apoyos MT',
    origin: 'gdoapoyo_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Apoyos BT',
    origin: 'gdoapoyo_baja_circular_vista',
    namespace: 'inventory.componente',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tramos MT',
    origin: 'gdotramo_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Autotrafos MT',
    origin: 'gdoautotrafo_m_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tramos BT',
    origin: 'gdotramo_baja_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tramos AT',
    origin: 'gdotramo_t_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tramos MT (Sin Servicio)',
    origin: 'gdotramoss_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Interruptores AT',
    origin: 'gdointerr_t_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Seccionadores AT',
    origin: 'gdosecci_t_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Seccionadores MT (Sin Servicio)',
    origin: 'gdosecciss_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Pasos Falta',
    origin: 'gdopasofalta_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Disyuntores',
    origin: 'gdodisyun_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Reguladores de tensión',
    origin: 'gdotraforeg_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Máquinas en CTs (Trafos de MT)',
    origin: 'gdotrafo_mb_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Posiciones en CTs (Celdas)',
    origin: 'gdocelda_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Posiciones en subestación',
    origin: 'gdoposicion_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Máquinas en Subestación',
    origin: 'gdotrafo_pot_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Trafos Servicios Auxiliares (En Subestación)',
    origin: 'gdotrafo_ssaa_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Parques en Subestación',
    origin: 'gdoparque_t_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Generadores',
    origin: 'gdogenerador_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Condensadores',
    origin: 'gdobia_condens_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Puentes Amovibles',
    origin: 'gdopuentam_circular_vista',
    namespace: 'inventory.componente',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Acometidas',
    origin: 'gdoacom_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Subestaciones',
    origin: 'gdosubestac_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Seccionadores MT',
    origin: 'gdosecci_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Empalmes MT/BT',
    origin: 'gdoempalme_circular_vista',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Interruptores MT',
    origin: 'gdointerr_circular_vista',
    fecha_baja: true,
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    origin: 'gdocuadro_baja_circular_vista',
    title: 'Cuadros de BT',
    namespace: 'inventory.componente',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    origin: 'gdointerr_baja_circular_vista',
    title: 'Interruptores BT',
    namespace: 'inventory.componente',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Autoválvulas MT',
    origin: 'gdoautoval_circular_vista',
    namespace: 'inventory.componente',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Salidas BT',
    origin: 'gdosalidasbt',
    namespace: 'inventory.componente',
    filteredConsult: true,
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tramos Regulatorios AT',
    origin: 'gdotramo_t_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Tramos Regulatorios MT',
    origin: 'gdotramo_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Tramos Regulatorios BT',
    origin: 'gdotramo_baja_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Centros de Transformación (CTs)',
    origin: 'gdoct_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Subestaciones',
    origin: 'gdosubestac_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Posiciones en subestación',
    origin: 'gdoposicion_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Máquinas en subestación',
    origin: 'gdotrafo_pot_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Condensadores',
    origin: 'gdobia_condens_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Reguladores de tensión',
    origin: 'gdotraforeg_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Seccionadores AT',
    origin: 'gdosecci_t_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Seccionadores MT',
    origin: 'gdosecci_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Interruptores MT',
    origin: 'gdointerr_regulatoria_circular_vista',
    namespace: 'inventory.regulatoria',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'diferred',
    specificReload3: 'undo-diferred',
    specificReloadNicename2: 'Diferidas',
    specificReloadNicename3: 'Diferidas',
  },
  {
    title: 'Tabla de Nudos',
    origin: 'conectividad',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Conectividad por tramos',
    origin: 'conex_linea_circular_vista',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Subestaciones en Depósito Repuestos Estratégicos',
    origin: 'subestaciones_deposito_repuestos_estrategicos_circular_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tramos AT Doble Circuito',
    origin: 'tramos_at_doble_circuito_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Nudos Proceso Desplazamiento',
    origin: 'nudos_desplazamiento_vista',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Ediciones recurrentes',
    origin: 'maestros_ediciones_manuales_recurrentes_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Identificadores SRE ',
    origin: 'sabana_inventario_sre_identificadores_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Formulario 1-20',
    origin: 'f1_f20_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Conectividad CTs SS',
    origin: 'conectividad_ctss_vista',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Puntos Frontera',
    origin: 'puntos_frontera_vista',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Depuración Maestro Puntos Frontera',
    origin: 'puntos_frontera_depuracion_vista',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Tabla de Nudos Resto Formularios',
    origin: 'tabla_nudos_resto_formularios_vista',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Nudos Formularios 10 MT/AT',
    origin: 'nudos_formulario_10_at_mt_vista',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Nudos Formularios 10 BT',
    origin: 'nudos_formulario_10_bt_vista',
    namespace: 'inventory.conectivity',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Posibles obras abiertas sin inversión',
    origin: 'gdo_posibles_obras_abiertas_sin_inversion_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Instalaciones de Alta sin expediente asociado',
    origin: 'gdo_instalaciones_alta_sin_expediente_asociado_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Parques en Depósito Estratégico y SSAA',
    origin: 'parques_depositos_estrategicos_ssaa_circular_vista',
    namespace: 'inventory.others',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Extracción FA1 SGC',
    origin: 'inventario_otros_f1_sgc_vista',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'f-forms',
    specificReloadNicename2: 'Extracción SGC',
    specificReload3: 'f-forms',
    specificReloadNicename3: 'Extracción Zeus',
  },
  {
    title: 'Extracción FA1 Zeus',
    origin: 'inventario_otros_f1_zeus_vista',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
    specificReload2: 'f-forms',
    specificReloadNicename2: 'Extracción SGC',
    specificReload3: 'f-forms',
    specificReloadNicename3: 'Extracción Zeus',
  },
  {
    title: 'Informe Previsión Demanda Suministro',
    origin: 'inventario_otros_informe_prevision_demanda_suministro',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Informe Previsión Generación Distribuida',
    origin: 'inventario_otros_informe_prevision_demanda_generacion',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },
  {
    title: 'Información A2/F3',
    origin: 'sabana_inventario_otras_informacion_a2_f3_vista',
    namespace: 'inventory.markets',
    reload: 'inventory',
    reloadNicename: 'Sábana de inventario',
    specificReloadNicename: 'Regulatoria',
    specificReload: 'regulatory',
  },

  {
    title: 'Formulario 1',
    origin: 'f1_vista',
    exportOrigin: 'f1',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 3',
    origin: 'f3_vista',
    exportOrigin: 'f3',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 4',
    origin: 'f4_vista',
    exportOrigin: 'f4',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 5',
    origin: 'f5_vista',
    exportOrigin: 'f5',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 6',
    origin: 'f6_vista',
    exportOrigin: 'f6',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 7',
    origin: 'f7_vista',
    exportOrigin: 'f7',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'F9 BT',
    origin: 'f9bt_vista',
    exportOrigin: 'f9bt',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReload: 'export-f9',
    specificReloadNicename: 'Recalcular exportaciones del F9',
  },
  {
    title: 'F9 MT',
    origin: 'f9mt_vista',
    exportOrigin: 'f9mt',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReload: 'export-f9',
    specificReloadNicename: 'Recalcular exportaciones del F9',
  },
  {
    title: 'F9 AT',
    origin: 'f9at_vista',
    exportOrigin: 'f9at',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReload: 'export-f9',
    specificReloadNicename: 'exportaciones del F9',
  },
  {
    title: 'Formulario 20',
    origin: 'f20_vista',
    exportOrigin: 'f20',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 2 A',
    origin: 'f2a_vista',
    exportOrigin: 'f2a',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 2 B',
    origin: 'f2b_vista',
    exportOrigin: 'f2b',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 10 AT',
    origin: 'f10at_vista',
    exportOrigin: 'f10at',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 10 MT',
    origin: 'f10mt_vista',
    exportOrigin: 'f10mt',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 10 BT',
    origin: 'f10bt_vista',
    exportOrigin: 'f10bt',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 11',
    origin: 'f11_vista',
    exportOrigin: 'f11',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 12',
    origin: 'f12_vista',
    exportOrigin: 'f12',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 12 Bis',
    origin: 'f12bis_vista',
    exportOrigin: 'f12bis',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 13',
    origin: 'f13_vista',
    exportOrigin: 'f13',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 13 Bis',
    origin: 'f13bis_vista',
    exportOrigin: 'f13bis',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 13 C',
    origin: 'f13c_vista',
    exportOrigin: 'f13c',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 14',
    origin: 'f14_vista',
    exportOrigin: 'f14',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 15',
    origin: 'f15_vista',
    exportOrigin: 'f15',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 16',
    origin: 'f16_vista',
    exportOrigin: 'f16',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 17',
    origin: 'f17_vista',
    exportOrigin: 'f17',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 18',
    origin: 'f18_vista',
    exportOrigin: 'f18',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 18 AT/MT',
    origin: 'f18atmt_vista',
    exportOrigin: 'f18atmt',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 18 BT',
    origin: 'f18bt_vista',
    exportOrigin: 'f18bt',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 19 Bis',
    origin: 'f19bis_vista',
    exportOrigin: 'f19bis',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 31',
    origin: 'f31_vista',
    exportOrigin: 'f31',
    namespace: 'forms.circular',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
  },
  {
    title: 'Formulario 1',
    origin: 'f1_inventario_vista',
    exportOrigin: 'f1_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 2',
    origin: 'f2_inventario_vista',
    exportOrigin: 'f2_inventario_vista',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 3',
    origin: 'f3_inventario_vista',
    exportOrigin: 'f3_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 4',
    origin: 'f4_inventario_vista',
    exportOrigin: 'f4_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 5',
    origin: 'f5_inventario_vista',
    exportOrigin: 'f5_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 6',
    origin: 'f6_inventario_vista',
    exportOrigin: 'f6_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 7',
    origin: 'f7_inventario_vista',
    exportOrigin: 'f7_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'Formulario 8',
    origin: 'f8_inventario_vista',
    exportOrigin: 'f8_inventario',
    namespace: 'forms.inventory',
    reload: 'forms',
    reloadNicename: 'Sábana de Formularios',
    specificReloadNicename: 'Sábana de Formularios e Informe de Instalaciones',
    specificReload: 'forms-and-installations',
  },
  {
    title: 'TIs VUR CTs',
    origin: 'maestros_vurctsmei',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'TIs VUR Elementos de Fiabilidad',
    origin: 'maestros_vuremfmei',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'TIs VUR Líneas',
    origin: 'maestros_vurlineasmei',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'TIs VUR Máquinas',
    origin: 'maestros_vurmaquinas',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'TIs VUR Posiciones',
    origin: 'maestros_vurposiciones',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'TIs VUR Reguladores',
    origin: 'maestros_tis_vur_reguladores',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Inversion Comparativa Componentes',
    origin: 'maestros_inversion_comparativa_componentes',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Provincias',
    origin: 'maestros_provincias',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Zonas base',
    origin: 'maestros_zona_base',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Ediciones Manuales',
    origin: 'maestros_ediciones_manuales',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Validaciones CNMC Información',
    origin: 'maestros_validaciones_cnmc_informacion',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Validaciones CNMC Formato',
    origin: 'maestros_validaciones_cnmc_formato',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Motivación-CINI',
    origin: 'maestros_motivacion_cini',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'TI-CINI',
    origin: 'maestros_ti_cini',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Maestro Identificadores SRE',
    origin: 'maestros_sre_identificadores',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Modo ingesta identificadores SRE',
    origin: 'maestros_sre_identificadores_modo_ingesta',
    namespace: 'masterdata.general',
    reload: 'masterdata',
  },
  {
    title: 'Delegaciones',
    origin: 'maestros_delegaciones',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'ELA Descripción',
    origin: 'maestros_ela_descripcion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Tipologías',
    origin: 'maestros_tipologias',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Unidades Físicas',
    origin: 'maestros_uucc',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Aportaciones MT/BT',
    origin: 'maestros_aportaciones',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Aportaciones AT y Subvenciones MT/BT',
    origin: 'maestros_aportaciones_at',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Reclasificación expediente masiva',
    origin: 'maestros_reclasificacion_expediente_masiva',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Obras con gasto - Clasificación Inversión',
    origin: 'maestros_obras_con_gasto',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Valoración Bajas',
    origin: 'maestros_valoracion_bajas',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Planificación',
    origin: 'maestros_planificacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Explicación Reclasificación',
    origin: 'maestro_explicacion_reclasificacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Valor Residual',
    origin: 'maestros_valor_residual',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Cuadros Resumen Inversiones',
    origin: 'maestros_cuadros_resumen_inversiones',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'UUCC Corregidas',
    origin: 'sabana_inversiones_cierre_obra_uucc_corregidas_vista',
    namespace: 'masterdata.investments',
    reload: 'investments',
  },
  {
    title: 'Expedientes PRTR',
    origin: 'maestros_prtr',
    namespace: 'masterdata.investments',
    reload: 'investments',
  },
  {
    title: 'Municipios',
    origin: 'maestros_municipio_de_4_ine',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Parámetros Eléctricos Normalizados MT/AT',
    origin: 'maestros_parametros_elec_normalizados_f10',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Pérdidas Trafo MT/BT/SSAA',
    origin: 'maestros_perdidas_trafo_mt_bt_f12',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Propiedad de las instalaciones',
    origin: 'maestros_propietario_tramos',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Relación de Parámetros Normalizados MT/AT',
    origin: 'maestros_relacion_param_bdi_norm_f10',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Relación de Parámetros Normalizados BT',
    origin: 'maestros_relacion_param_norm_f10bt',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Equivalencia de Códigos con REE',
    origin: 'maestros_tabla_cruce_con_ree',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Comparativa',
    origin: 'maestros_comparativa',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Comparativa Regulatoria',
    origin: 'maestros_comparativa_regulatoria',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Maestros Temas Depuración Atributos (Tipo Alta)',
    origin: 'maestros_tipo_alta',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Parques Depósitos Estratégicos',
    origin: 'maestros_parques_depositos_estrategicos',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Subestaciones Depósito Repuestos Estratégicos',
    origin: 'maestros_subestaciones_depositos_estrategicos',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Subestaciones Repetidas',
    origin: 'maestros_subestaciones_repetidas_circular_vista',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Parques asociados a Trafos de SSAA',
    origin: 'maestros_parques_asociados_trafos_ssaa',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Dobles Circuitos Puros AT',
    origin: 'maestros_dobles_circuitos_puros_at',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Autoseccionadores',
    origin: 'maestros_autoseccionadores',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Autotrafos en CTs',
    origin: 'maestros_autotrafos',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Máquinas en CTs Autotrafos',
    origin: 'maestros_maquinas_cts_autotrafos',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Ubicaciones Base Subestaciones Móviles',
    origin: 'maestros_ubicaciones_base_subestaciones_moviles',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'CTs Prefijo Cambiado',
    origin: 'maestros_cts_prefijo_cambiado',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Tramos Provincia Nudo Final',
    origin: 'maestros_tramos_provincia_nudo_final',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Comparativa Inventario',
    origin: 'maestros_comparativa_inventario',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Tramos AT Transporte Inventario',
    origin: 'maestros_tramos_at_transporte_inventario',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Maestros Temas Depuración Trabajos',
    origin: 'maestros_temas_depuracion',
    reload: 'masterdata',
  },
  {
    title: 'Pérdidas Trafo AT F14',
    origin: 'maestros_perdidas_trafo_at_f14',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Tensiones Aislamiento Tramos MT/AT',
    origin: 'maestros_tensiones_aislamiento_tramos_mt_at',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Tensiones Tramos MT',
    origin: 'maestros_tensiones_gdotramo',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Configuraciones TI Permitidas',
    origin: 'maestros_configuraciones_ti_permitidas',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'EMFs en Tensiones Distintas',
    origin: 'maestros_emf_tensiones_distintas',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Codificación estados inventario',
    origin: 'maestros_codificacion_estado_inventario',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Codificación estados regulatoria',
    origin: 'maestros_codificacion_estado_regulatoria',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Instalaciones transmitidas',
    origin: 'maestros_instalaciones_transmitidas',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Puntos frontera base',
    origin: 'maestros_puntos_frontera_base',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Energías Punto Frontera',
    origin: 'maestros_energias_puntos_frontera',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Códigos distribuidoras',
    origin: 'maestros_codigos_distribuidoras',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Zona de calidad',
    origin: 'maestros_zona_calidad',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Asociación Nudos y Provincias MT/AT',
    origin: 'maestros_asociacion_nudos_provincias_mt_at',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Asociación Nudos y Provincias BT',
    origin: 'maestros_asociacion_nudos_provincias_bt',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },

  {
    title: 'Conversión CNAE SGC',
    origin: 'maestros_conversion_cnae_sgc',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },

  {
    title: 'Tensiones F1',
    origin: 'maestros_tensiones_f1',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },

  {
    title: 'Ine Autonomía Provincia Municipio',
    origin: 'maestros_ine_autonomia_provincia_municipio',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },

  {
    title: 'Codificación de Tarifas',
    origin: 'maestros_codificacion_tarifas',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Relación horas generador',
    origin: 'maestros_relacion_horas_generador',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Expedientes Año Previsto',
    origin: 'maestros_expedientes_anio_previsto',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },

  {
    title: 'Centrales',
    origin: 'maestros_centrales',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Centrales RO',
    origin: 'maestros_centrales_ro',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Contratos',
    origin: 'maestros_contratos',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },
  {
    title: 'Energía intercambiada generadores',
    origin: 'maestros_energia_intercambiada_generadores',
    namespace: 'masterdata.inventory',
    reload: 'masterdata',
  },

  {
    title: 'Formularios Circular',
    origin: 'maestros_circular_configuracion_vista',
    namespace: 'masterdata.forms',
    reload: 'masterdata',
  },
  {
    title: 'Formularios Inventario',
    origin: 'maestros_formularios_inventario_configuracion_vista',
    namespace: 'masterdata.forms',
    reload: 'masterdata',
  },
  {
    title: 'Formularios A',
    origin: 'maestros_formularios_a_configuracion_vista',
    namespace: 'masterdata.forms',
    reload: 'masterdata',
  },
  {
    title: 'Parques asociados a Máquinas Formulario 14',
    origin: 'maestros_parques_asociados_maquinas_f14_vista',
    namespace: 'masterdata.forms',
    reload: 'masterdata',
  },
  {
    title: 'Pretratamiento F1 F20',
    origin: 'maestros_pretratamiento_f1_f20',
    namespace: 'masterdata.forms',
    reload: 'masterdata',
  },

  //FORMULARIOS AUDITORIA
  {
    title: 'Formulario 1',
    origin: 'sabana_formularios_f1_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Formulario 2',
    origin: 'sabana_formularios_f2_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Formulario 4',
    origin: 'sabana_formularios_f4_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Formulario 5',
    origin: 'sabana_formularios_f5_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Formulario 6',
    origin: 'sabana_formularios_f6_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Formulario 7',
    origin: 'sabana_formularios_f7_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Formulario 8',
    origin: 'sabana_formularios_f8_auditoria_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },
  {
    title: 'Alertas auditoría',
    origin: 'sabana_formularios_auditoria_importes_ajustados_vista',
    namespace: 'auditoria',
    reload: 'audit-forms',
    reloadNicename: 'Formularios Auditoría',
  },

  //FORMULARIOS CNMC A

  {
    title: 'Formulario A1',
    origin: 'fa1_vista',
    namespace: 'cnmc_a',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
    specificReload: 'cnmc-a',
    specificReloadNicename: 'Formularios A',
    hasValidations: true,
  },
  {
    title: 'Formulario A2',
    origin: 'fa2_vista',
    namespace: 'cnmc_a',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
    specificReload: 'cnmc-a',
    specificReloadNicename: 'Formularios A',
    hasValidations: true,
  },

  {
    title: 'Formulario A3',
    origin: 'fa3_vista',
    namespace: 'cnmc_a',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
    specificReload: 'cnmc-a',
    specificReloadNicename: 'Formularios A',
    hasValidations: true,
  },
  {
    title: 'Formulario A4',
    origin: 'fa4_vista',
    namespace: 'cnmc_a',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
    specificReload: 'cnmc-a',
    specificReloadNicename: 'Formularios A',
    hasValidations: true,
  },
  {
    title: 'Formulario A5',
    origin: 'fa5_vista',
    namespace: 'cnmc_a',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
    specificReload: 'cnmc-a',
    specificReloadNicename: 'Formularios A',
  },

  //FORMULARIOS CNMC B
  {
    title: 'Formulario B1 BT',
    origin: 'fb1bt_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B1 MT',
    origin: 'fb1mt_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B1 AT',
    origin: 'fb1at_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B1.1 BT',
    origin: 'fb1_1bt_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B1.1 MT',
    origin: 'fb1_1mt_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B1.1 AT',
    origin: 'fb1_1at_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B2',
    origin: 'fb2_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B2.1',
    origin: 'fb2_1_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B2.2',
    origin: 'fb2_2_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B3',
    origin: 'fb3_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B3.1',
    origin: 'fb3_1_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B3.2',
    origin: 'fb3_2_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B3.3',
    origin: 'fb3_3_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B4',
    origin: 'fb4_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B5',
    origin: 'fb5_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B6',
    origin: 'fb6_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B7 AT/MT',
    origin: 'fb7atmt_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B7 BT',
    origin: 'fb7bt_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B8',
    origin: 'fb8_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },
  {
    title: 'Formulario B9',
    origin: 'fb9_vista',
    namespace: 'cnmc_b',
    reload: 'cnmc',
    reloadNicename: 'Formularios CNMC',
  },

  {
    title: 'Configuración SGT-SGM',
    origin: 'maestros_sgt_sgm_configuracion_vista',
    namespace: 'masterdata.transactional',
  },
  {
    title: 'Cesiones',
    origin: 'manual_cesiones',
    namespace: 'masterdata.transactional',
  },
  {
    title: 'Asociación Expediente-Tipología SGM/SGT',
    origin: 'sgm_expediente_tipologia',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Información Transaccional GDA',
    origin: 'gda_transaccional_obras',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'HDTER',
    origin: 'hdter_aux',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Datos Expediente Cerrado SGT',
    origin: 'sgt_expediente_cerrado',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Datos Expediente Cerrado SGM',
    origin: 'sgm_expediente_cerrado',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Asociación Expediente-UUOO SGT',
    origin: 'sgt_presupuestouuoo',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Asociación Expediente-UUOO SGM',
    origin: 'sgm_presupuestouuoo',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Asociacion Expediente-UUCC SGT',
    origin: 'sgt_presupuestouucc',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Asociacion Expediente-UUCC SGM',
    origin: 'sgm_presupuestouucc',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },

  {
    title: 'SGT Gdadesc',
    origin: 'sgt_gdadesc',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'SGT Gdarelac',
    origin: 'sgt_gdarelac',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },

  {
    title: 'Acta Puesta en Marcha',
    origin: 'maestros_acta_puesta_en_marcha',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Obras Abiertas',
    origin: 'maestros_obras_abiertas',
    namespace: 'masterdata.transactional',
    reload: 'masterdata',
  },
  {
    title: 'Jerarquía de activos',
    origin: 'maestros_arbol_jerarquico_componente_regulatoria_vista',
    namespace: 'masterdata.assets',
    reload: 'masterdata',
  },
  {
    title: 'Maestros Clasificacion MEIs',
    origin: 'maestros_clasificacion_meis',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Maestros Clase Coste Tipo',
    origin: 'maestros_clase_coste_tipo',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Maestros CINI Clase Coste Tipo (TPI)',
    origin: 'maestros_cini_clase_coste_tipo',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Asociacion UUCC-MEI',
    origin: 'asociacion_uucc_mei',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Asociacion UUCC-Tipo de instalacion',
    origin: 'asociacion_uucc_tipo_instalacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Clasificación MEI-Tipo instalación',
    origin: 'maestros_clasificacion_mei_tipo_instalacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Comparativa VUR',
    origin: 'maestros_comparativa_vur',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'UUCC Despachos',
    origin: 'maestros_uucc_despachos',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'CINI-MEI',
    origin: 'maestros_cini_mei',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Reparto de bolsas',
    origin: 'maestros_vtr_tipologia_actuacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'VTR-Código actuación',
    origin: 'maestros_vtr_codigo_actuacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Mapeo Tipologías SGM',
    origin: 'maestros_sgm_mapeo_tipologias',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Asociación UUCC-Despachos',
    origin: 'maestros_uucc_despachos',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Asociación de Obras',
    origin: 'maestros_asociacion_obra',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Validaciones Estado-Motivación',
    origin: 'maestros_validaciones_estado_motivacion',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Expedientes Auditados ',
    origin: 'maestros_expedientes_auditados',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },
  {
    title: 'Adelanto de inversiones',
    origin: 'maestros_adelanto_inversiones',
    namespace: 'masterdata.investments',
    reload: 'masterdata',
  },

  // Inventments Adjustment

  {
    title: 'Fichero SAP Incurridos',
    origin: 'sap_incurridos_staging',
    namespace: 'investments_adjustment.incurridos_origen',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Incurridos Origen',
  },
  {
    title: 'BDR SAP Incurridos',
    origin: 'sabana_cuadre_inversiones_incurridos_origen_bdr_sap_incurridos',
    namespace: 'investments_adjustment.incurridos_origen',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Incurridos Origen',
  },

  {
    title: 'Otros Años',
    origin: 'sabana_cuadre_inversiones_entradas_inversion_otros_anios',
    namespace: 'investments_adjustment.entradas_inversion',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Entradas Inversión',
  },
  {
    title: 'En el Año',
    origin: 'sabana_cuadre_inversiones_entradas_inversion_anio',
    namespace: 'investments_adjustment.entradas_inversion',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Entradas Inversión',
  },

  {
    title: 'Otros Años',
    origin: 'sabana_cuadre_inversiones_salidas_inversion_otros_anios',
    namespace: 'investments_adjustment.salidas_inversion',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Salidas Inversión',
  },
  {
    title: 'En el Año',
    origin: 'sabana_cuadre_inversiones_salidas_inversion_anio',
    namespace: 'investments_adjustment.salidas_inversion',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Salidas Inversión',
  },

  {
    title: 'Exclusiones en el Año',
    origin: 'sabana_cuadre_inversiones_exclusiones_anio',
    namespace: 'investments_adjustment.exclusiones_anio',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Exclusiones en el Año',
  },

  {
    title: 'MT/BT',
    origin: 'sabana_cuadre_inversiones_detalle_traspaso_agregado_obra_mtbt',
    namespace: 'investments_adjustment.detalle_traspaso_agregado_obra',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Detalle Traspaso - Agregado por Obra',
  },
  {
    title: 'Despachos',
    origin: 'sabana_cuadre_inversiones_detalle_traspaso_agregado_obra_despachos',
    namespace: 'investments_adjustment.detalle_traspaso_agregado_obra',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Detalle Traspaso - Agregado por Obra',
  },

  {
    title: 'MT/BT',
    origin: 'sabana_cuadre_inversiones_agregado_obra_pco_mtbt',
    namespace: 'investments_adjustment.agregado_obra_pco',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Agregado por Obra - PCO',
  },
  {
    title: 'Despachos',
    origin: 'sabana_cuadre_inversiones_agregado_obra_pco_despachos',
    namespace: 'investments_adjustment.agregado_obra_pco',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Agregado por Obra - PCO',
  },

  {
    title: 'PCO - IID',
    origin: 'sabana_cuadre_inversiones_pco_iid',
    namespace: 'investments_adjustment.pco_iid',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'PCO - IID',
  },

  {
    title: 'Validaciones',
    origin: 'sabana_cuadre_inversiones_validaciones',
    namespace: 'investments_adjustment.validaciones',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Validaciones',
  },

  {
    title: 'Resumen Entradas y Salidas Inversión',
    origin: 'sabana_cuadre_inversiones_resumen_entradas_salidas_vista',
    namespace: 'investments_adjustment.resumen_entradas_salidas_inversion',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Resumen Entradas y Salidas Inversión',
  },

  {
    title: 'Seguimiento Inversión en BDR',
    origin: 'sabana_cuadre_inversiones_seguimiento_inversion_vista',
    namespace: 'investments_adjustment.seguimiento_inversion_bdr',
    reload: 'investments-adjustment',
    reloadNicename: 'Cuadre de Inversiones',
    specificReloadNicename: 'Seguimiento Inversión en BDR',
  },

  // Inventments

  {
    title: 'Detalle Traspaso Incurridos',
    origin: 'sabana_inversiones_mtbt_detalle_vista',
    namespace: 'investments.mtbt',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'mtbt',
    specificReloadNicename: 'MT/BT',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
    massEdit: 'mass_edit_mtbt',
    hasMassEdition: true,
    hasValidations: true,
  },
  {
    title: 'Agregado por obra',
    origin: 'sabana_inversiones_mtbt_agregada_vista',
    namespace: 'investments.mtbt',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'mtbt',
    specificReloadNicename: 'MT/BT',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
    hasValidations: true,
  },
  {
    title: 'Detalle Traspaso Incurridos',
    origin: 'sabana_inversiones_at_detalle_traspaso_vista',
    namespace: 'investments.at',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'at',
    specificReloadNicename: 'AT',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
    hasValidations: true,
  },
  {
    title: 'Traspaso Agregado',
    origin: 'sabana_inversiones_at_traspaso_agregado_vista',
    namespace: 'investments.at',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'at',
    specificReloadNicename: 'AT',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
    hasValidations: true,
  },
  {
    title: 'Traspaso Agregado por ELA ',
    origin: 'sabana_inversiones_at_traspaso_agregado_ela_vista',
    namespace: 'investments.at',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'at',
    specificReloadNicename: 'AT',

    hasValidations: true,
  },
  {
    title: 'Edificios/Varios',
    origin: 'sabana_inversiones_edificios_varios_vista',
    namespace: 'investments.buildings',
    reload: 'investments',
    specificReload: 'buildings-and-others',
    specificReloadNicename: 'Edificios/Varios',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' y Diferidas',
  },
  {
    title: 'Detalle Traspaso Incurridos',
    origin: 'sabana_inversiones_despachos_detalle_vista',
    namespace: 'investments.dispatchers',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'dispatchers',
    specificReloadNicename: 'Despachos',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
  },
  {
    title: 'Agregado por obra',
    origin: 'sabana_inversiones_despachos_agregado_obra_vista',
    namespace: 'investments.dispatchers',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'dispatchers',
    specificReloadNicename: 'Despachos',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
    hasValidations: true,
  },
  {
    title: 'Equipos de Medida',
    origin: 'sabana_inversiones_equipos_medida_vista',
    namespace: 'investments.equipment',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'measuring-equipments',
    specificReloadNicename: 'Equipos de medida',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
  },
  {
    title: 'Histórico reclasificaciones',
    origin: 'sap_incurridos_manual_registro_vista',
    namespace: 'investments.reclassifications',
  },
  {
    title: 'Apuntes diferidos',
    origin: 'sap_incurridos_manual_apuntes_diferidos_vista',
    namespace: 'investments.reclassifications',
  },
  {
    title: 'Reclasificaciones agrupadas',
    origin: 'sap_incurridos_manual_registro_agrupado_vista',
    namespace: 'investments.reclassifications',
  },
  {
    title: 'Instalaciones adelantadas',
    origin: 'sabana_inventario_instalaciones_adelantadas_vista',
    namespace: 'investments.reclassifications',
  },
  {
    title: 'Incurridos Agregados OT / CLASE DE COSTE',
    origin: 'sabana_inversiones_at_traspaso_agregado_ela_vista',
    namespace: 'investments.atEla',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'at',
    specificReloadNicename: 'AT',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
  },
  {
    title: 'Instalaciones Inventario',
    origin: 'sabana_inversiones_at_detalle_ela_instalaciones_vista',
    namespace: 'investments.atEla',
    reload: 'investments',
    reloadNicename: 'Sábana de Inversiones',
    specificReload: 'at',
    specificReloadNicename: 'AT',
    specificReload2: 'differ-and-investments',
    specificReloadNicename2: ' Sábana de Inversiones y Diferidas Inventario',
  },
];

export const origins = {
  allOrigins,
  investmentsAdjustment: {
    incurridos_origen: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.incurridos_origen',
    ),
    entradas_inversion: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.entradas_inversion',
    ),
    salidas_inversion: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.salidas_inversion',
    ),
    exclusiones_anio: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.exclusiones_anio',
    ),
    detalle_traspaso_agregado_obra: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.detalle_traspaso_agregado_obra',
    ),
    agregado_obra_pco: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.agregado_obra_pco',
    ),
    pco_iid: allOrigins.filter((item) => item.namespace === 'investments_adjustment.pco_iid'),
    resumen_entradas_salidas_inversion: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.resumen_entradas_salidas_inversion',
    ),
    seguimiento_inversion_bdr: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.seguimiento_inversion_bdr',
    ),
    validaciones: allOrigins.filter(
      (item) => item.namespace === 'investments_adjustment.validaciones',
    ),
  },
  investments: {
    mtbt: allOrigins.filter((item) => item.namespace === 'investments.mtbt'),
    at: allOrigins.filter((item) => item.namespace === 'investments.at'),
    buildings: allOrigins.filter((item) => item.namespace === 'investments.buildings'),
    dispatchers: allOrigins.filter((item) => item.namespace === 'investments.dispatchers'),
    equipment: allOrigins.filter((item) => item.namespace === 'investments.equipment'),
    reclassifications: allOrigins.filter(
      (item) => item.namespace === 'investments.reclassifications',
    ),
  },
  retributiveYears: 'years',
  investmentsFilter: 'investment_sheet',
  validationsSummaryMtbt: 'sabana_inversiones_mtbt_resumen_coherencia',
  validationsSummaryAt: 'sabana_inversiones_at_resumen_coherencia',
  // validationsFilterMtbt: 'sabana_inversiones_coherencia',
  validationsFilterMtbt: 'sabana_inversiones_mtbt_coherencia',
  validationsFilterAt: 'sabana_inversiones_at_detalle_traspaso_vista',
  filteredValidationsSummary: 'validations_summary',
  loadsLogQuery: 'system/stl_query',
  loadsLogError: 'stl_load_errors',
  loadsLogAudit: 'bdr_audit',
  masterDataPaths: 'landing_files',
  masterDataGenerateUrl: 'landing_files/generate_upload_url',
  inventory: {
    componente: allOrigins
      .filter((item) => item.namespace === 'inventory.componente')
      .sort(originSort),
    regulatoria: allOrigins
      .filter((item) => item.namespace === 'inventory.regulatoria')
      .sort(originSort),
    otras: allOrigins.filter((item) => item.namespace === 'inventory.others').sort(originSort),
    conectividad: allOrigins.filter((item) => item.namespace === 'inventory.conectivity').sort(originSort),
    mercadosAtendidos: allOrigins.filter((item) => item.namespace === 'inventory.markets').sort(originSort)
  },
  installations: {
    instalaciones: allOrigins
      .filter((item) => item.namespace === 'installations.instalaciones')
      .sort(originSort),

    desagrupado: allOrigins
      .filter((item) => item.namespace === 'installations.desagrupado')
      .sort(originSort),

    inspeccion: allOrigins
      .filter((item) => item.namespace === 'installations.inspeccion')
      .sort(originSort),

    comunicaciones: allOrigins
      .filter((item) => item.namespace === 'installations.comunicaciones')
      .sort(originSort),

    cuadro_resumen: allOrigins
      .filter((item) => item.namespace === 'installations.cuadro_resumen')
      .sort(originSort),
    despachos: allOrigins
      .filter((item) => item.namespace === 'installations.despachos')
      .sort(originSort),
    expedientes_auditados: allOrigins
      .filter((item) => item.namespace === 'installations.expedientes_auditados')
      .sort(originSort),
    listado_sres: allOrigins
      .filter((item) => item.namespace === 'installations.listado_sres')
      .sort(originSort),
  },
  forms: {
    inversionplan: [],
    circular: allOrigins.filter((item) => item.namespace === 'forms.circular').sort(originSort),
    inventory: allOrigins.filter((item) => item.namespace === 'forms.inventory').sort(originSort),
    auditoria: allOrigins.filter((item) => item.namespace === 'auditoria').sort(originSort),
    cnmc_a: allOrigins.filter((item) => item.namespace === 'cnmc_a').sort(originSort),
    cnmc_b: allOrigins.filter((item) => item.namespace === 'cnmc_b').sort(originSort),
    cnmc_c: allOrigins.filter((item) => item.namespace === 'cnmc_c').sort(originSort),
  },

  validations: [
    'coherencia_mei_incurridos',
    'coherencia_instalaciones_obra',
    'coherencia_instalaciones_todos_mei_obra',
    'coherencia_instalaciones_importe',
    'coherencia_ddigitalizadas',
  ],
  workDetail: 'work_detail',
  validationsDetail: 'work_detail',
  mastersLoad: 'maestros_load',
  investmentsSheetGenerate: 'generate_sabana_inversiones',
  inventorySheetGenerate: 'semifull_bdi',
  formsSheetGenerate: 'formularios_load',
  typologiesMaster: 'maestros_tipologias',
  sheetState: 'status',
  tasks: 'tasks',
  task: 'task',
  workload: 'workload',
  user_filters: 'filters',
  log: 'log',
  filters: [
    'sabana_inversiones_mtbt_detalle_vista',
    'sgt_presupuestouucc',
    'sgm_presupuestouucc',
    'gdoct_circular_vista',
    'gdosecci_circular_vista',
    'gdoapoyo_circular_vista',
  ],
};
export const namespaceNicenames = nicenames();

function nicenames() {
  let nicenames = {};
  allOrigins.forEach((origin) => {
    let nicename = '<unknown>';
    let namespace = origin.namespace;
    if (namespace) {
      if (namespace.includes('inventory')) {
        nicename = 'la sábana de inventario';
      } else if (namespace.includes('forms')) {
        nicename = 'la sábana de formularios';
      } else if (namespace.includes('cnmc')) {
        nicename = 'la sábana de Formularios CNMC';
      } else if (namespace.includes('investment') || namespace.includes('assets')) {
        nicename = 'la sábana de inversiones';
      }
    }
    nicenames[origin.origin] = nicename;
  });
  return nicenames;
}

function originSort(a, b) {
  return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
}
