import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { DataApiService } from '../../../../services/data-api/data-api.service';
import { NotificationsService } from '../../../../services/notifications/notifications.service';

@Component({
  selector: 'work-associates',
  templateUrl: './work-associates.component.html',
  styleUrls: ['./work-associates.component.scss']
})
export class WorkAssociatesComponent implements OnInit {
  installationType = new UntypedFormControl();
  installationId = new UntypedFormControl({ value: '', disabled: true });

  installations: any[];
  installationsToSend: any[] = [];

  hasError: boolean = false;
  validating: boolean = false;

  @Output() onAssociateInstallation: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    public dialogRef: MatDialogRef<WorkAssociatesComponent>,
    private dataApiService: DataApiService,
    private notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.installations = data.installations;
    this.initFormFields();
  }

  ngOnInit() {
    this.initFormFields();
  }

  initFormFields() {
    this.installationType.valueChanges.subscribe(value => {
      if (value) {
        this.installationId.enable();
      } else {
        this.installationId.disable();
        this.installationId.reset();
      }
    });
  }

  close(): void {
    this.reset();
    this.dialogRef.close();
  }

  save() {
    this.onAssociateInstallation.emit({
      installations: this.installations,
      installationsToSend: this.installationsToSend
    });
    this.dialogRef.close();
  }

  add(event: Event) {
    event.stopPropagation();

    const check = {
      year: '2019',
      installation: {
        id: this.installationId.value,
        type: this.installationType.value
      }
    };

    this.validating = true;
    this.dataApiService.validateInstallation(check).subscribe(res => {
      if (res.valid) {
        this.hasError = false;
        let inventoryInstallation = res.component;
        let installationCode = inventoryInstallation.codigo_bdr_instalaciones;

        let existingInstallation = this.installationsToSend.find(inst => inst.codigo_bdr_instalaciones === installationCode);
        if (existingInstallation) {
          if (existingInstallation.action === 0) {
            existingInstallation.action = 1;
          } else {
            this.installationsToSend.push(existingInstallation);
          }
        } else {
          const installation = {
            anio_contable: this.data.retributiveYear,
            codigo_obra: this.data.workCode,
            identificador: installationCode,
            from_inventory_sgm_sgt: true,
            action: 1
          };
          this.installationsToSend.push(installation);
        }

        this.installations.push({
          codigo_bdr_instalaciones: installationCode,
          codigo_bdr_instalaciones_final: installationCode,
          codigo_obra: this.data.workCode,
          secuencial: Math.max(...this.installations.filter(i => i.codigo_bdr_instalaciones_final === installationCode).map(i => i.secuencial), 0) + 1,
          tipo_instalacion: inventoryInstallation.tipo_instalacion,
          comparativa_inventario: inventoryInstallation.comparativa,
          comparativa_inversiones: inventoryInstallation.comparativa,
          vtr: inventoryInstallation.tipo_instalacion.toLowerCase().includes('rbt') ? inventoryInstallation.vtr / 1000 : inventoryInstallation.vtr,
          longitud_bdi: inventoryInstallation.longitud_bdi,
          origen_instalacion: 'Manual',
          new: true,
        });

        if (inventoryInstallation.anio_contable !== inventoryInstallation.anio_retributivo) {
          this.notificationsService.add({
            type: "warning",
            message: "La instalación que se ha asociado está diferida en el inventario del año actual",
            fixed: false,
            popup: true
          });
        }
      } else {
        this.hasError = true;
        this.installationId.reset();
      }
      this.validating = false;
    });

  }

  remove(index: number) {
    let alreadyAddedInstallation = this.installationsToSend
      .find(installation => installation.codigo_bdr_instalaciones === this.installations[index].codigo_bdr_instalaciones);

    if (alreadyAddedInstallation) {
      alreadyAddedInstallation.action = 0
    } else {
      let installationId = this.installations[index].codigo_bdr_instalaciones;
      const installation = {
        anio_contable: this.data.retributiveYear,
        codigo_obra: this.data.workCode,
        identificador: installationId,
        from_inventory_sgm_sgt: this.origin(installationId),
        action: 0
      };
      this.installationsToSend.push(installation);
    }
    this.installations.splice(index, 1);
  }

  private origin(installationId) {
    return this.installations.filter(i => i.codigo_bdr_instalaciones === installationId)
      .some(i => i.origen_instalacion && i.origen_instalacion !== 'Manual');
  }

  reset() {
    this.installationsToSend = [];
  }
}
