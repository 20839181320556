import { Component } from '@angular/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { VERSION } from '../../../environments/version';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

export const LOGIN_MESSAGES = {
  'username.email': 'Email: Debe tener formato de Email',
  'username.required': 'Email es obligatorio',
  'password.required': 'La contraseña es obligatoria',
  'password.validationInfo': 'La contraseña debe contener al menos 8 caracteres.',
  'password.minLength': 'La contraseña debe tener al menos 8 caracteres',
  'password.validationInfo.passwordLength': '8 caracteres o más',
  'password.validationInfo.passwordCase': 'letras mayúsculas y minúsculas',
  'password.validationInfo.passwordNumbers': 'al menos un número',
  'password.validationInfo.passwordSpecialChars': 'al menos un caracter especial ($%&/*#)'
};

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  version: any;
  username = new UntypedFormControl('', [Validators.email, Validators.required]);
  password = new UntypedFormControl('', Validators.required);
  name = new UntypedFormControl('', Validators.required);
  familyName = new UntypedFormControl('', Validators.required);
  isForbidden = false;
  newPassword = new UntypedFormControl('', [
    Validators.minLength(8),
    Validators.required,
    this.passwordPatternValidation
  ]);
  accessToken: string;
  verificationCode = new UntypedFormControl('', Validators.required);
  userAttributes: any;
  passwordReset = false;
  firstLogin = false;
  initialMsg = '';
  error = false;
  errorMsg = '';
  usernameError = false;
  usernameErrorMsg = '';
  passwordError = false;
  passwordErrorMsg = '';
  newPasswordErrorMsg = '';
  newPasswordValidationInfo = {
    passwordLength: {
      text: LOGIN_MESSAGES['password.validationInfo.passwordLength'],
      error: false
    },
    passwordCase: {
      text: LOGIN_MESSAGES['password.validationInfo.passwordCase'],
      error: false
    },
    passwordNumber: {
      text: LOGIN_MESSAGES['password.validationInfo.passwordNumbers'],
      error: false
    },
    passwordSymbol: {
      text: LOGIN_MESSAGES['password.validationInfo.passwordSpecialChars'],
      error: false
    }
  };

  recoveryPwdBtn = true;

  constructor(private auth: AuthService, private ar: ActivatedRoute) {
    this.version = VERSION;
  }

  ngOnInit() {
    const fragment = this.ar.snapshot.fragment;
    if (fragment && fragment.startsWith('error=unauthorized')) {
      this.isForbidden = true;
    }
    const code = this.auth.recoveryCode;
    if (code !== '') {
      this.verificationCode.setValue(code);
      this.passwordReset = true;
    }
  }

  passwordPatternValidation(control) {
    const passwordLowerCaseRegex = new RegExp(/(.*[a-z]+)/g);
    const passwordUpperCaseRegex = new RegExp(/(.*[A-Z]+)/g);
    const passwordNumberRegex = new RegExp(/(.*[0-9]+)/g);
    const passwordSymbolRegex = new RegExp(/(.*[$%&/*#]+)/g);
    let result = <any>{};
    delete result.passwordLowerCase;
    if (!passwordLowerCaseRegex.test(control.value)) {
      result.passwordLowerCase = {
        valid: false
      };
    }
    delete result.passwordUpperCase;
    if (!passwordUpperCaseRegex.test(control.value)) {
      result.passwordUpperCase = { valid: false };
    }
    delete result.passwordNumber;
    if (!passwordNumberRegex.test(control.value)) {
      result.passwordNumber = { valid: false };
    }
    delete result.passwordSymbol;
    if (!passwordSymbolRegex.test(control.value)) {
      result.passwordSymbol = { valid: false };
    }
    result.valid = !(
      result.passwordSymbol ||
      result.passwordLowerCase ||
      result.passwordUpperCase ||
      result.passwordNumber
    );
    return result.valid ? null : result;
  }

  validate() {
    if (this.firstLogin || this.passwordReset) {
      this.validateNewPassword();
    }

    if (this.username.errors && this.username.errors.required) {
      this.usernameErrorMsg = LOGIN_MESSAGES['username.required'];
      this.error = true;
      return;
    }
    if (this.username.errors && this.username.errors.email) {
      this.usernameErrorMsg = LOGIN_MESSAGES['username.email'];
      this.error = true;
      return;
    }
    this.usernameErrorMsg = '';
    if (this.password.errors && this.password.errors.required && !this.passwordReset) {
      this.passwordErrorMsg = LOGIN_MESSAGES['password.required'];
      this.error = true;
      return;
    }
    if (this.error) {
      return;
    }
    this.passwordErrorMsg = '';

    this.newPasswordErrorMsg = '';
    this.error = false;
  }

  validateNewPassword() {
    if (this.newPassword.errors) {
      this.newPasswordValidationInfo.passwordLength.error =
        this.newPassword.errors.hasOwnProperty('minlength') || this.newPassword.value.length === 0;
      this.newPasswordValidationInfo.passwordCase.error =
        this.newPassword.errors.hasOwnProperty('passwordLowerCase') ||
        this.newPassword.errors.hasOwnProperty('passwordUpperCase');
      this.newPasswordValidationInfo.passwordSymbol.error = this.newPassword.errors.hasOwnProperty(
        'passwordSymbol'
      );
      this.newPasswordValidationInfo.passwordNumber.error = this.newPassword.errors.hasOwnProperty(
        'passwordNumber'
      );

      this.error =
        this.error ||
        this.newPassword.errors.hasOwnProperty('minlength') ||
        this.newPasswordValidationInfo.passwordNumber.error ||
        this.newPasswordValidationInfo.passwordSymbol.error ||
        this.newPasswordValidationInfo.passwordCase.error;
    } else {
      this.newPasswordValidationInfo.passwordLength.error = false;
      this.newPasswordValidationInfo.passwordCase.error = false;
      this.newPasswordValidationInfo.passwordCase.error = false;
      this.newPasswordValidationInfo.passwordSymbol.error = false;
      this.newPasswordValidationInfo.passwordNumber.error = false;
      this.error = false;
    }
  }

  login() {
    this.error = false;
    this.errorMsg = '';
    this.usernameError = false;
    this.usernameErrorMsg = '';
    this.passwordError = false;
    this.passwordErrorMsg = '';

    if (this.username.value.length === 0) {
      this.usernameError = true;
      this.usernameErrorMsg = 'Usuario inválido';
    }

    if (this.password.value.length === 0) {
      this.passwordError = true;
      this.passwordErrorMsg = 'Contraseña inválida';
    }

    if (!this.error && !this.usernameError && !this.passwordError) {
      this.auth.login(this.username.value, this.password.value, (err: any, userAttributes: any) => {
        switch (err.code) {
          case 'first_login':
            this.firstLogin = true;
            this.userAttributes = userAttributes;
            break;
          case 'PasswordResetRequiredException':
            this.passwordReset = true;
            break;
          case 'invalid_username':
            this.usernameError = true;
            this.usernameErrorMsg = 'Nombre de usuario no válido';
            break;
          case 'NotAuthorizedException':
            this.errorMsg = 'Usuario o contraseña incorrecta';
            break;
          default:
            this.errorMsg = `Error desconocido.`;
        }
      });
    }
  }

  objectKeys(item) {
    return Object.keys(item);
  }

  loginIDP() {
    this.auth.loginIDP();
  }
}
