<div class="login-wrapper">
    <div class="login">
        <div class="login-header">
            <h2 class="login-title">BDR</h2>
            <p class="login-subtitle">(Base de Datos Retributiva)</p>
        </div>
        <div class="login-footer">
            <bdr-icon [esir]="true" class="login-admin-icon" icon="user" size="24px"></bdr-icon>
            <span class="login-footer__access" (click)="loginIDP()">Acceso federado</span>
        </div>
        <div class="login-footer" *ngIf="isForbidden" style="color: red">
            <span class="version">Usuario no autorizado en la aplicación</span>
        </div>
        <div class="login-footer">
            <span class="version">1.48.0</span>
        </div>
    </div>
</div>
