import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { cloneDeep } from 'lodash';
import { RECLASSIFICATION_TYPES } from '../../../environments/common/default-views';

interface Columns {
  name: string;
  label: string;
  type?: 'checkbox' | 'input' | 'select';
  options?: string[];
  width?: number;
}

@Component({
  selector: 'bdr-table',
  templateUrl: './bdr-table.component.html',
  styleUrls: ['./bdr-table.component.scss'],
})
export class BdrTableComponent {
  @ViewChild(MatTable, { read: ElementRef, static: false }) private matTableRef: ElementRef;
  @Input() items: any[] = [];
  @Input() columns: Columns[] = [];
  @Input() isEditable: boolean;
  @Input() selectableRows: boolean;
  @Input() validationsNicenames?: any;
  @Input() hasSelect?: boolean = true;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Output() changeSelect: EventEmitter<any> = new EventEmitter();
  @Output() changeInput: EventEmitter<any> = new EventEmitter();
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  selection = new SelectionModel<any>(true, []);
  totalRows: number;

  ngOnChanges() {
    if (this.items) {
      this.dataSource = new MatTableDataSource<any>(this.items);
      this.totalRows = this.dataSource.data.length;
    }

    if (this.isEditable && this.columns) {
      this.columns = cloneDeep(this.columns);
    }

    if (this.columns) {
      if (this.isEditable) {
        this.columns = cloneDeep(this.columns);
      }
      if (this.columns.length > 0) {
        this.displayedColumns = this.columns.map((column) => column.name);
        // this.setResizableColumns(this.matTableRef.nativeElement);
        if (this.selectableRows && this.hasSelect) {
          this.displayedColumns.unshift('select');
        }
      }
    }
  }

  trackByFn(index) {
    return index;
  }

  clearSelection() {
    this.selection.clear();
  }

  setResizableColumns(matTable: MatTable<any>) {
    const options = {
      draggingClass: 'rangeDrag',
      gripInnerHtml: '<div class="rangeGrip"></div>',
      widths: this.setColumnsSize(this.columns),
      disabledColumns: [0],
      minWidth: 100,
    };
  }

  setColumnsSize(columns: Columns[]) {
    let sizes = columns.map((column) => 250);

    if (this.selectableRows) {
      sizes[0] = 50;
      sizes.push(250);
    }

    return sizes;
  }

  checkbox(row) {
    this.selection.toggle(row);
    this.emitSelectedChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));

    this.emitSelectedChanges();
  }

  private emitSelectedChanges() {
    return this.onSelected.emit(this.selection.selected);
  }

  compareFn(c1, c2): boolean {
    return c1 === c2;
  }

  onChangeSelect(value, row): void {
    this.changeSelect.emit(row);
  }

  onChangeInput(event, row): void {
    event.stopPropagation();
    this.changeInput.emit(row);
  }

  isString(value: string): boolean {
    return !Number(value);
  }

  getUFDValidationNicename(validationId) {
    return this.validationsNicenames[validationId].name;
  }

  getReclassificationTypeNicename(reclassificationTypeId) {
    return RECLASSIFICATION_TYPES[reclassificationTypeId];
  }

}
