import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './../components/components.module';
// Components - Containers

import { GridHandlerViewComponent } from './grid-handler-view/grid-handler-view.component';

// Services
import { DataApiService } from '../services/data-api/data-api.service';
import { GridHandlerViewModule } from './grid-handler-view/grid-handler-view.module';

@NgModule({
    imports: [CommonModule, ComponentsModule, GridHandlerViewModule],
    exports: [GridHandlerViewComponent],
    declarations: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [DataApiService]
})
export class ContainersModule { }
