import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';import { MastersService } from '../../../../../app/services/masters/masters.service';
import { COHERENCE_PROCESSES_MTBT } from '../../../../services/bdr-validations/bdr-validations.service';

/* constante que se utiliza para dar un ancho fijo,
  al campo 'INFORMACION TIPOLOGIAS ACTUACION',
  para que las posiciones cuadren, el total son 24 columnas pero se excluyen 2 */
const TI_BDI = 'ti_bdi';

@Component({
  selector: 'bdr-table-installations',
  templateUrl: './bdr-table-installations.component.html',
  styleUrls: ['./bdr-table-installations.component.scss'],
})
export class TableInstallationsComponent implements OnChanges {
  @ViewChild(MatTable, { read: ElementRef, static: false }) private matTableRef: ElementRef;
  @Input() columns: any[] = [];
  @Input() installations: any[] = [];
  @Input() options: any[] = [];
  @Input() workCode;
  @Input() canEdit: boolean;
  @Input() automaticAmounts: string = 'NO';
  
  @Input() filteredColumns: any[] = [];

  headerColumns;
  displayedColumns;
  tipologies;

  filteredInstallations = [];
  visibleFastFilters: boolean = false;

  @Output() onEditInstallation = new EventEmitter<any>();

  constructor(
    private mastersService: MastersService
  ) {
    this.mastersService.getPerformanceTypologies()
      .subscribe(result => {
        this.tipologies = result;
      });
  }

  ngOnChanges() {
    this.filteredInstallations = this.installations;   

    if (this.columns.length > 0) {
      this.setHeaders(this.columns);
    }

    if (this.filteredColumns.length > 0) {
      this.displayedColumns = this.filteredColumns.map((column) => column.name);
    }

  }

  setHeaders(headers) {
    this.headerColumns = headers.map((header) => {
      const inputFields = ['justificacion', 'codigo_bdr_instalaciones_final'];
      const isInputType = inputFields.includes(header.name);
      if (isInputType) {
        header.type = 'input';
      }

      const selectFields = ['tipologias_actuacion', 'comparativa_inversiones', 'cedido'];

      const isSelectField = selectFields.includes(header.name);
      if (isSelectField) {
        header.type = 'select';
      }

      return header;
    });

    this.displayedColumns = this.headerColumns
      .filter((header) => header.visible)
      .map((header) => header.name);
  }

  getInstallationOptions(field, installationType: string, investmentsComparative: string) {
    let options;
    switch (field) {
      case 'tipologias_actuacion':
        options = this.tipologies
          .filter(t => t.tipo_instalacion_posible === installationType && t.tipo_comparativa_posible === investmentsComparative)
          .map(t => t.nivel2);
        break;
      case 'comparativa_inversiones':
        options = ['Alta', 'Sin Cambios', 'Modificación', 'Sin inversión'];
        break;
      case 'cedido':
        options = ['SÍ', 'NO'];
        break;
      default:
        options = []
    }
    return options;
  }

  handleEditInstallation(value, installation, secuencial, field: string, codigo_obra_original_aux, comparativa_inversiones) {
    this.onEditInstallation.emit({
      value: value,
      installation: installation,
      secuencial: secuencial,
      field: field,
      codigo_obra: codigo_obra_original_aux,
      comparativa_inversiones: comparativa_inversiones
    });
  }

  compareFn(c1, c2): boolean {
    return c1 === c2;
  }

  isString(value: string): boolean {
    return !Number(value);
  }

  getValidationsName(id: string) {
    return COHERENCE_PROCESSES_MTBT[id].name;
  }
  
  isSticky(column: string): boolean { 
    return ["codigo_bdr_instalaciones_final", "secuencial"].includes(column)? true : false;
  }

  nameFilter = new UntypedFormControl();

  appliedFilters = new Map<string, string>();

  filterData(event: any, columnName: string) { 
    if (event) { 
      this.appliedFilters.set(columnName, event);
    } else { 
      this.appliedFilters.delete(columnName)
    }
    this.applyFilters();
  }

  applyFilters() {
    let x = this.installations.filter( installation => {
      let v = []
      this.appliedFilters.forEach((value: string, key: string) => v.push([key, value]));
      return installation.filter || v.every(x=> installation[x[0]]===x[1])
    });
    
    if (!this.visibleFastFilters) { 
      this.filteredInstallations = x
    } else { 
      let filterRow = Object.assign({}, this.installations[0]);;
      filterRow.filter = true;
      this.filteredInstallations = [filterRow, ...x];
    }
  }

  filterFieldOptions(columnName: string) { 
    return [""].concat([... new Set(this.installations.map(i=>i[columnName]))]);
  }


  toggleFilters() { 
    this.visibleFastFilters = !this.visibleFastFilters;
    this.applyFilters()
  }

  installationWarning(row) { 
    return ['Alta'].includes(row.comparativa_inversiones) &&
      (this.automaticAmounts === 'SI' && row.comparativa_vur_automatica > 150 || row.comparativa_vur > 150)
    ;
  }

  installationError(row){
    return ['Sin Cambios', 'Modificación'].includes(row.comparativa_inversiones) && 
      (this.automaticAmounts === 'SI' && row.comparativa_vur_automatica > 85 || row.comparativa_vur > 85);
  }

}
